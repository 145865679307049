<template>
  <div>
     <img width="100%" src="../../assets/images/hesuan.jpg" alt="">
  </div>
</template>

<script>
export default {

}
</script>

<style>

</style>